import { ref, watch, computed } from "@vue/composition-api";
import axios from "axios";
export default function useTickets() {
  /* Status Colors */
  const statusColors = [
    { value: "primary", label: "Blue" },
    { value: "danger", label: "Red" },
    { value: "success", label: "Green" },
    { value: "warning", label: "Yellow" },
    { value: "info", label: "Light Blue" },
  ];

  /* Tickets */
  const ticketsTableRef = ref(null);

  const refUser = ref(null);
  const refLastReplyUser = ref(null);
  const refStatus = ref(null);
  const refPriority = ref(null);
  const refDepartment = ref(null);
  const isLoading = ref(false);
  const isLoadingDep = ref(false);
  const isLoadingStatues = ref(false);

  const ticketsTableFields = [
    { key: "ticketNumber", label: "Ticket#" },
    {
      key: "user",
      label: "User",
    },
    {
      key: "subject",
      label: "Subject",
    },
    {
      key: "department",
      label: "Department",
    },
    {
      key: "status",
      label: "Status",
    },
    {
      key: "priority",
      label: "Priority",
    },
    {
      key: "lastReply",
      label: "Last Reply",
    },
  ];

  /* Departments Table Field */
  const depTableRef = ref(null);
  const depTableFields = [
    { key: "name", label: "Name" },
    { key: "slug", label: "Slug" },
    { key: "email", label: "Email Address" },
    { key: "status", label: "Status" },
    { key: "actions", label: "Actions" },
  ];
  const fetchDepartments = async () => {
    try {
      isLoadingDep.value = true;
      const { data } = await axios.get("/support/departments");
      return data.data;
    } catch (e) {
      console.log(e.message);
      return [];
    } finally {
      isLoadingDep.value = false;
    }
  };

  const ticketPriorities = [
    { value: null, text: "--ALL--", color: "" },
    { value: "low", text: "Low", color: "success" },
    { value: "medium", text: "Medium", color: "warning" },
    { value: "high", text: "High", color: "danger" },
  ];

  const fetchTickets = async () => {
    try {
      isLoading.value = true;
      const formData = new FormData();
      formData.append("userId", refUser.value ? refUser.value.value : "");
      formData.append("lastReply", refLastReplyUser.value ? lastReplyUser.value.value : "");
      formData.append("departmentId", refDepartment.value ? refDepartment.value : "");
      formData.append("priority", refPriority.value ? refPriority.value : "");
      const { data } = await axios.post("/support/tickets/list", formData);
      return data.data;
    } catch (e) {
      console.log(e.message);
      return [];
    } finally {
      isLoading.value = false;
    }
  };
  watch([refUser, refLastReplyUser, refStatus, refPriority, refDepartment], () => {
    ticketsTableRef.value.refresh();
  });

  return {
    depTableFields,
    depTableRef,
    fetchDepartments,
    ticketsTableFields,
    ticketsTableRef,
    ticketPriorities,

    refUser,
    refLastReplyUser,
    refStatus,
    refPriority,
    refDepartment,
    fetchTickets,
    isLoading,
    isLoadingDep,
    isLoadingStatues,
  };
}
