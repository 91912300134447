<template>
  <div id="CreateNewTicket">
    <b-row>
      <b-col cols="7" class="mx-auto">
        <b-card no-body>
          <b-card-header><h5>Create New Support Ticket</h5></b-card-header>
          <b-card-body>
            <b-row>
              <b-col cols="12">
                <b-alert
                  :show="message.text != '' && message.type != ''"
                  class="p-1"
                  :variant="message.type"
                  >{{ message.text }}</b-alert
                >
              </b-col>
              <b-col cols="12" v-if="getRole != 'User'">
                <b-form-group label="Search User">
                  <v-select v-model="userId" :options="users" @search="searchUser" clearable />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Subject">
                  <b-form-input v-model="subject" />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Department">
                  <b-form-select :options="departments" v-model="department" />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Priority">
                  <b-form-select
                    :options="filterEmptyOptions(ticketPriorities)"
                    v-model="priority"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4" v-if="getRole != 'User'">
                <b-form-group label="Status">
                  <b-form-select :options="statuses" v-model="status" />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Message">
                  <quill-editor v-model="msg" />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-button
                  :disabled="
                    !msg ||
                    !subject ||
                    (getRole != 'User' && !userId) ||
                    !department ||
                    !priority ||
                    creating
                  "
                  variant="primary"
                  @click="doCreate"
                  >{{ creating ? "wait..." : "Create Ticket" }}</b-button
                >
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import axios from "axios";
import useTickets from "../useTickets";
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BAlert,
  BCardHeader,
  BCardBody,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BLink,
} from "bootstrap-vue";
import { quillEditor } from "vue-quill-editor";
import vSelect from "vue-select";
export default {
  name: "CreateNewTicket",
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BLink,
    vSelect,
    quillEditor,
    BButton,
    BAlert,
  },
  data() {
    return {
      users: [],
      userId: null,
      subject: "",
      department: "",
      priority: "",
      status: "",
      message: "",
      msg: "",
      creating: false,
      departments: [],
      statuses: [],
      message: {
        text: "",
        type: "",
      },
    };
  },
  methods: {
    filterEmptyOptions: function (options) {
      return options.filter((x) => x.value);
    },
    loadStatuses: async function () {
      try {
        const { data } = await axios.get("/support/status");
        this.statuses = data.data.map((x) => {
          return {
            value: x._id,
            text: x.name,
          };
        });
      } catch (e) {
      } finally {
      }
    },
    loadDepartments: async function () {
      try {
        const { data } = await axios.get("/support/departments");
        this.departments = data.data.map((x) => {
          return {
            value: x._id,
            text: x.name,
          };
        });
      } catch (e) {
      } finally {
      }
    },
    searchUser: async function (query) {
      if (query.length < 3) {
        this.users = [];
        return;
      }
      try {
        const formData = new FormData();
        formData.append("username", query);
        formData.append("perPage", 10);
        formData.append("page", 1);
        const { data } = await axios.post("/support/usernameSearch", formData);
        this.users = data.data.map((x) => {
          return { value: x._id, label: `${x.username} - ${x.userType}` };
        });
      } catch (e) {
        this.users = [];
      }
    },
    doCreate: async function () {
      this.creating = true;
      try {
        const formData = new FormData();
        if (this.userId) {
          formData.append("userId", this.userId.value);
        }

        formData.append("subject", this.subject);
        formData.append("departmentId", this.department);
        formData.append("priority", this.priority);
        if (this.status) {
          formData.append("ticketStatus", this.status);
        }
        formData.append("message", this.msg);
        const { data } = await axios.post("/support/tickets", formData);
        if (this.userId) {
          this.userId.value = "";
        }
        this.subject = "";
        this.department = "";
        this.priority = "";
        if (this.status) {
          this.status = "";
        }
        this.msg = "";
        this.message = {
          text: "Ticket Created Successfully",
          type: "success",
        };
      } catch (e) {
        console.log(e);
        /* this.message = {
          text: e.response.data.error.message || e.message,
          type: "danger",
        }; */
      } finally {
        this.creating = false;
      }
    },
  },
  mounted() {
    this.loadDepartments();
    this.loadStatuses();
  },
  computed: {
    getRole() {
      return this.$store.getters["auth/getRole"];
    },
  },
  setup() {
    const { ticketPriorities } = useTickets();
    return {
      ticketPriorities,
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
</style>
